import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import classNames from 'classnames';
import { createPageUrl } from '../../app/redirects';
import settings from '../../app/settings';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IForecastDayInterval } from '../../model/forecast';
import { ILocation } from '../../model/location';
import { IWarning } from '../../model/warning';
import { DAILY_PERIODS } from '../../modelHelpers/weatherSymbol';
import { ClickableLink } from '../Clickable/Clickable';
import { Heading } from '../Heading/Heading';
import { MinMaxTemperature } from '../MinMaxTemperature/MinMaxTemperature';
import { Precipitation } from '../Precipitation/Precipitation';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import { Text } from '../Text/Text';
import { WarningsIconGroup } from '../WarningsIconGroup/WarningsIconGroup';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Wind } from '../Wind/Wind';
import './DailyWeatherListItem.scss';

interface IProps {
  forecastDayInterval: IForecastDayInterval;
  index: number;
  location: ILocation;
  warnings: IWarning[];
}

export function DailyWeatherListItem(props: IProps) {
  const { index, location, warnings, forecastDayInterval } = props;

  const localeCode = useLocaleCode();
  const translate = useTranslate();
  const { precipitation, start, temperature, wind } = forecastDayInterval;
  const hasWarnings = warnings != null && warnings.length > 0;
  const id = `dailyWeatherListItem${index}`;
  const param = settings.path.query.index;

  const hourlyForecastUrl = location
    ? createPageUrl({
        localeCode,
        pageId: 'forecast',
        subpageId: 'hourly-table',
        locationId: location.id,
        urlPath: location.urlPath,
        search: `?${param}=${index}`
      })
    : '';

  return (
    <li className="daily-weather-list-item" id={id} key={`daily-${start}`} role="region">
      <div className="daily-weather-list-item__date-and-warnings">
        <ClickableLink
          className="daily-weather-list-item__item-date"
          href={hourlyForecastUrl}
          title={translate('dailyWeatherListItem/linkTitle')}
        >
          <Heading level="h3" size="4" data-testid="daily-weather-list-item-date-heading">
            <RelativeTime
              type="relative-day-with-date-medium"
              time={start}
              relativeTodayOnly={true}
              transform="sentence-case"
            />
          </Heading>
        </ClickableLink>
        <div className="daily-weather-list-item__warnings">
          <div className="daily-weather-list-item__warnings-mobile">
            {hasWarnings && <WarningsIconGroup warnings={warnings} size={3} />}
          </div>
          <div className="daily-weather-list-item__warnings-desktop">
            {hasWarnings && <WarningsIconGroup stack={true} warnings={warnings} size={4} />}
          </div>
        </div>
      </div>

      <ol className="daily-weather-list-item__symbols">
        {forecastDayInterval.sixHourSymbols.map((sixHourSymbol, index) => {
          if (sixHourSymbol != null) {
            const id = convertSymbolKeyToId(sixHourSymbol);

            return (
              <li key={index} className={`daily-weather-list-item__symbol daily-weather-list-item__symbol-${index}`}>
                <WeatherSymbol id={id} period={DAILY_PERIODS[index]} />
              </li>
            );
          }

          // Add an empty list item to represent the missing period
          return (
            <li key={index} className={`daily-weather-list-item__symbol daily-weather-list-item__symbol-${index}`} />
          );
        })}
      </ol>

      <div className="daily-weather-list-item__forecast">
        <div className="daily-weather-list-item__temperature" role="text">
          <span className={'nrk-sr'}>{translate('dailyWeatherList/headings/temperature/long')}:</span>
          <Text size="3" tabularNums={true}>
            <MinMaxTemperature temperature={temperature} />
          </Text>
        </div>
        <div
          className={classNames('daily-weather-list-item__precipitation', {
            'nrk-sr': precipitation.value === 0,
            'daily-weather-list-item__precipitation--no-precipitation': precipitation.value === 0
          })}
        >
          <Text size="4" tabularNums={true}>
            <Precipitation value={precipitation.value} displayZero={false} />
          </Text>
        </div>
        <div className="daily-weather-list-item__wind" role="text">
          <span className="nrk-sr">{translate('dailyWeatherList/headings/wind/long')}:</span>
          <Text size="4" tabularNums={true}>
            <Wind
              className="daily-weather-list-item__wind-value"
              unitClassName="daily-weather-list-item__wind-unit"
              value={wind.max}
              decimal={false}
              debug={{ caller: 'DailyWeatherListItem', api: 'forecast', locationId: location.id }}
            />
          </Text>
        </div>
      </div>
    </li>
  );
}
